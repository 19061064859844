import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPrivateService, apiPublicService } from "api/api";
import { siteSlice } from "../reducers/SiteSlice";
import Cookies from "js-cookie";
import i18n from "locale/i18n";
import { toast } from "react-toastify";
import { fetchData } from "./data";


export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(siteSlice.actions.setIsLoading(true));
    try {
      const response = await apiPublicService.post("/oauth/token", data?.data);
      if (response.status === 200 && response.data.access_token) {
        Cookies.set("access_token", response.data.access_token);
        Cookies.set("refresh_token", response.data.refresh_token);
        data?.cb();
        thunkAPI.dispatch(fetchData())
        return response.data;
      }
    } catch (e) {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      if (e?.response?.status === 400 || e.response.data.error == "invalid_grand") {

        return thunkAPI.rejectWithValue(i18n.store.data[i18n.language].translation["Invalid grand"]);

      }
      return thunkAPI.rejectWithValue(e.message);
    } finally {

      thunkAPI.dispatch(siteSlice.actions.setIsLoading(false));
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, thunkAPI) => {
    // thunkAPI.dispatch(siteSlice.actions.setIsLoading(true));
    thunkAPI.dispatch(siteSlice.actions.setRegisterLoading(true));
    try {
      const response = await apiPublicService.post("/register", data?.data);

      if (response.status === 200) {
        thunkAPI.dispatch(siteSlice.actions.setIsLoading(true));
        if (data?.cb) data.cb()
        thunkAPI.dispatch(siteSlice.actions.setRegisterLoading(false));
        thunkAPI.dispatch(siteSlice.actions.setIsLoading(false));
        toast.success(i18n.store.data[i18n.language].translation["success registered"])
        return response

      }
      else
        return thunkAPI.rejectWithValue(i18n.store.data[i18n.language].translation["Näsazlyk, täzeden barlap görüň"])
    } catch (e) {
      if (e.response && e.response.status === 400) {
        if (e.response?.data?.error?.username) {
          toast.error(i18n.store.data[i18n.language].translation["user has already registered"])
        }
        else if (e.response?.data?.error?.email) {
          toast.error(i18n.store.data[i18n.language].translation["email has already registered"])

        }
        else
          toast.error(i18n.store.data[i18n.language].translation["input data is incorrect"])
      }
      // return thunkAPI.rejectWithValue(e.message);
    } finally {
      thunkAPI.dispatch(siteSlice.actions.setIsLoading(false));
      thunkAPI.dispatch(siteSlice.actions.setRegisterLoading(false));
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(siteSlice.actions.setIsLoading(true));
    try {
      const response = await apiPrivateService.post("/logout", null);
      if (response.status === 200 && response.data.status === 1) {
        data?.cb();
        return response.data;
      }
    } catch (e) {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      return thunkAPI.rejectWithValue(e.message);
    } finally {

      thunkAPI.dispatch(siteSlice.actions.setIsLoading(false));
    }
  }
);



