import axios from "axios"
import { config } from "config"
import Cookies from 'js-cookie'
const httpAdapter = require('axios/lib/adapters/http');

export const apiPrivateService = axios.create({
    baseURL: config.apiURL + '/api',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access":"application/json"
    }
    
})
apiPrivateService.interceptors.request.use((config: any)=>{
    const token = Cookies.get('access_token') || null
    config.headers.Authorization = `Bearer ${token}`
    return config
})


export const apiPublicService = axios.create({
    baseURL: config.apiURL + '/api',
    adapter: httpAdapter,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    },
})

export const apiPublicImagesService = axios.create({
    baseURL: config.apiURL + '/api',
    adapter: httpAdapter,
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'multipart/form-data'
    },
})

apiPublicImagesService.interceptors.request.use((config: any)=>{
    const token = Cookies.get('access_token') || null
    config.headers.Authorization = `Bearer ${token}`
    return config
})