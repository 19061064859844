import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'


import tm from './tm'
import ru from './ru'
import en from './en'

const resources = {
    tm: {
        translation: tm
    },
    ru: {
        translation: ru
    },
    en: {
        translation: en
    },
}

let defaultLocale = localStorage.getItem('locale')

if(defaultLocale === null)
{
    localStorage.setItem('locale', 'tm')
    defaultLocale = localStorage.getItem('locale')
}

// @ts-ignore
i18n.use(initReactI18next)
// @ts-ignore
    .init({
        resources,
        lng: defaultLocale,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n