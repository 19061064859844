import { createSlice } from "@reduxjs/toolkit";
import {  fetchData, fetchSchedule } from "../actioncreators/data";

const initialState = {
  data: null,
  schedule: null,
  isLoading: null,
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    // @ts-ignore
    setIncrementToCredit: ({state, action}: {state: {data: {offered_credits: [{}],}}, action: {payload: string}}) => {
      state.data.offered_credits.push(action.payload);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  // @ts-ignore
  extraReducers: {
    [fetchData.pending.type]: (state: {isLoading : boolean}) => {
      state.isLoading = true;
    },
    [fetchData.fulfilled.type]: (state: {isLoading: boolean, data: any}, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [fetchData.rejected.type]: (state: {isLoading: boolean}) => {
      state.isLoading = false;
    },
    [fetchSchedule.pending.type]: (state: {isLoading: boolean}) => {
      state.isLoading = true;
    },
    [fetchSchedule.fulfilled.type]: (state: {isLoading: boolean, schedule: any}, action) => {
      // debugger
      state.schedule = action.payload.schedule;
      state.isLoading = false;
    },
    [fetchSchedule.rejected.type]: (state: {isLoading: boolean}) => {
      state.isLoading = false;
    },
  },
});

export default dataSlice.reducer;
