import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPrivateService } from "../../api/api";
import { AuthSlice } from "../reducers/AuthSlice.ts";
import Cookies from "js-cookie";
import { refreshToken } from "./site";
const refresh_token = Cookies.get("refresh_token");

export const fetchData = createAsyncThunk(
  "data/fetchData",
  async (_, thunkAPI) => {
    // thunkAPI.dispatch(siteSlice.actions.setIsLoading(true))
    try {
      const response = await apiPrivateService.get("/org");
      const isPerson = response.data.data.who === "person";
      var data = response.data.data;
      const applicant_card = response.data.data.offered_cards;
      const applicant_credit = response.data.data.offered_credits;
      const applicant_document = response.data.data.offered_documents;
      var applicants = applicant_card.concat(
        applicant_credit,
        applicant_document
      );
      applicants.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      data.applicants = applicants;
      thunkAPI.dispatch(AuthSlice.actions.setIsPerson(isPerson));
      // thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
      return data;
    } catch (e) {
      if (refresh_token && e.response?.status === 401) {
        thunkAPI.dispatch(refreshToken({ action: fetchData, data: "" }));
        return thunkAPI.rejectWithValue(e.message);
      } else {
        Cookies.remove("access_token");
        // thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const fetchSchedule = createAsyncThunk(
  "data/fetchSchedule",
  async (_, thunkAPI) => {
    try {
      const response = await apiPrivateService.get("/org/schedule");
      if(response.status === 200){
        thunkAPI.dispatch(AuthSlice.actions.setIsAuth(true));
        return response.data;
      }
    } catch (e) {
      if (refresh_token && e.response?.status === 401) {
        thunkAPI.dispatch(refreshToken({ action: fetchSchedule, data: "" }));
      } else {
        thunkAPI.dispatch(AuthSlice.actions.setIsAuth(false));
        Cookies.remove("access_token");
        return thunkAPI.rejectWithValue(e.message);
      }
      // thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
    }
  }
);
