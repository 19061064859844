import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutesNames } from "../../../routes/routes";

const PublicRoute = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const location = useLocation();
  const from = location.state?.from?.pathname || RoutesNames.ROOM;
  return !isAuth ? <Outlet /> : <Navigate to={from} replace />;
};

export default PublicRoute;
