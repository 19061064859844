import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "./components/common/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { fetchSchedule } from "redux/actioncreators/data";
import PublicRoute from "components/common/PublicRoute/PublicRoute";
import { RoutesNames, publicRoutes, userRoutes } from "routes/routes";
import AuthRoute from "components/common/AuthRoute/AuthRoute";
import Watchers from "api/watchers";
import EmailVerfiy from "components/Main/EmailVerfiy";

const AlertModal = React.lazy(() =>
  import("./components/common/AlertModal/AlertModal")
);

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: any) => state.auth.isAuth);
  const token = Cookies.get("access_token");
  const { isLoading } = useSelector((state: any) => state.site);
  const fetchingData = useSelector((state: any) => state.data.isLoading);
  const [watch, setWatch] = React.useState<boolean>(false)

  useEffect(() => {
    if (token) {
      dispatch(fetchSchedule());
      setWatch(true)
    }
  }, [token]);

  if (isLoading) return <Loader />;
  return (
    <>
      {watch === true ? <Watchers /> : null}
      <ToastContainer />
      {fetchingData && <Loader />}
        <React.Suspense fallback={<Loader />}>
          <AlertModal />
          <Routes>
            <Route element={<PublicRoute />}>
              {publicRoutes.map((route, i: number) => {
                return (
                  <Route
                    index={route.index}
                    path={route.path}
                    key={`${route.path}-${i}`}
                    element={route.component}
                  />
                );
              })}
            </Route>
            <Route element={<AuthRoute />}>
              {userRoutes.map((privateRoute, i: number) => {
                return (
                  <Route
                    path={privateRoute.path}
                    key={`${privateRoute.path}-${i}`}
                    element={privateRoute.component}
                  />
                );
              })}
            </Route>
            {isAuth ? (
              <Route path="*" element={<Navigate to={RoutesNames.ROOM} />} />
            ) : (
              <Route path="*" element={<Navigate to={RoutesNames.LOGIN} />} />
            )}
          </Routes>
        </React.Suspense>
    </>
  );
};

export default App;
