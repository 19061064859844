import React from "react";

const Main = React.lazy(() => import("../pages/Main/Main"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Register = React.lazy(() => import("../pages/Auth/Register"));
const Restore = React.lazy(() => import("../pages/Auth/Restore"));

const Room = React.lazy(() => import("../pages/Room/Room"));
const Card = React.lazy(() => import("../pages/Room/Card/Card"));
const Credit = React.lazy(() => import("../pages/Room/Credit/Credit"));
const Document = React.lazy(() => import("../pages/Room/Document/Document"));
const PasswordChange = React.lazy(() =>
  import("../pages/Room/PasswordChange/PasswordChange")
);
const Feedback = React.lazy(() => import("../pages/Room/Feedback"));
const Reception = React.lazy(() => import("../pages/Room/Reception"));
const Profile = React.lazy(() => import("../pages/Room/Profile"));
const ProfileEdit = React.lazy(() => import("../pages/Room/Profile/edit"));
const ProfileAddMore = React.lazy(() => import("../pages/Room/Profile/add-more"));

export const RoutesNames = {
  INDEX: "/",
  LOGIN: "/online/auth/login",
  REGISTER: "/online/auth/register",
  RESTORE: "/online/password/reset/:token",
  CREDIT: "https://loan.tnbk.tm",
  CARD: "https://sargyt.tnbk.tm/cards",
  DOCUMENT: "/online/room/document",  // FEEDBACK: "/online/room/feedback",
  // RECEPTION: "/online/room/reception",
  PROFILE: "/online/room/profile",
  PROFILEEDIT: "/online/room/profile/edit",
  PROFILEADDMORE: "/online/room/profile/add-more",
  CHANGEPASSWORD: "/online/room/change-password",
  ROOM: "/online/room",
};

export const publicRoutes = [
  {
    path: RoutesNames.INDEX,
    index: false,
    component: <Main />,
  },
  {
    path: RoutesNames.LOGIN,
    index: false,
    component: <Login />,
  },
  {
    path: RoutesNames.REGISTER,
    index: false,
    component: <Register />,
  },
  {
    path: RoutesNames.RESTORE,
    index: false,
    component: <Restore />,
  },
  // {
  //     path:'*',
  //     index:false,
  //     component: <Navigate to={RoutesNames.INDEX} replace/>
  // }
];

export const userRoutes = [
  {
    path: RoutesNames.CARD,
    title: "Kart bölümi",
    index: false,
    component: <Card />,
  },
  {
    path: RoutesNames.CREDIT,
    title: "Karz bölümi",
    index: false,
    component: <Credit />,
  },
  // {
  //   path: RoutesNames.EMAIL_VERIFICATION,
  //   index: false,
  //   title: "Email Verify",
  //   component: <EmailVerification />,
  // },
  {
    path: RoutesNames.DOCUMENT,
    title: "Güwanama bölümi",
    index: false,
    component: <Document />,
  },
  {
    path: RoutesNames.ROOM,
    title: "Baş sahypa",
    index: false,
    component: <Room />,
  },

  {
    path: RoutesNames.CHANGEPASSWORD,
    title: "Gizin acary calyshmak",
    index: false,
    component: <PasswordChange />,
  },
  // {
  //   path: RoutesNames.FEEDBACK,
  //   title: "Arz shikayat",
  //   index: false,
  //   component: <Feedback />,
  // },
  // {
  //   path: RoutesNames.RECEPTION,
  //   title: "Online kabulhana",
  //   index: false,
  //   component: <Reception />,
  // },
  {
    path: RoutesNames.RECEPTION,
    title: "Online kabulhana",
    index: false,
    component: <Reception />,
  },
  {
    path: RoutesNames.PROFILE,
    index: false,
    component: <Profile />,
  },
  {   
    title: "Profile",
    path: RoutesNames.PROFILEEDIT,
    // title: "ProfileEdit",
    index: false,
    component: <ProfileEdit />,
  },
  {   
    title: "ProfileAddMore",
    path: RoutesNames.PROFILEADDMORE,
    title: "ProfileAddMore",
    index: false,
    component: <ProfileAddMore />,
  },

  // {
  //     path:'*',
  //     index:false,
  //     component: <Navigate to={RoutesNames.ROOM} replace/>
  // }
];
