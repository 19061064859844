const dataPhones: IComponents.IDataPhones[] = [
    {
        id: 49,
        name: 'ashgabat',
        number: ['+993 12-96-46-41']
    },
    {
        id: 50,
        name: 'mary',
        number: ['+993 522-7-08-29', '+993 522-7-08-62']
    },
    {
        id: 51,
        name: 'balkan',
        number: ['+993 222-2-33-88', '+993 222-2-33-66']
    },
    {
        id: 52,
        name: 'lebap',
        number: ['+993 422-4-35-20', '+993 422-4-35-24', '+993 422-4-35-21', '+993 422-4-35-28']
    },
    {
        id: 53,
        name: 'dasoguz',
        number: ['+993 12-96-46-06', '+993 12-96-46-87', '+993 322-2-42-91', '+993 322-2-42-84']
    },
];

const branchinfos: {id: number; name: string}[] = [
    {"id": 1, "name": "Baş bank"},
    {"id": 2, "name": "Köpetdag şahamçasy"},
    {"id": 3, "name": "Kesearkaç şahamçasy"},
    {"id": 4, "name": "Galkynyş şahamçasy"},
    {"id": 5, "name": "Diýar şahamçasy"},
    {"id": 6, "name": "Altyn asyr şahamçasy"},
    {"id": 7, "name": "Gundogar şahamçasy"},
    {"id": 8, "name": "Büzmeýin şahamçasy"},
    {"id": 9, "name": "Bagtyýarlyk şahamçasy"},
    {"id": 10, "name": "Tejen şahamçasy"},
    {"id": 11, "name": "Ahal şahamçasy"},
    {"id": 12, "name": "Bäherden şahamçasy"},
    {"id": 13, "name": "Arkadag ş. Türkmenistan DTB"},
    {"id": 14, "name": "Serdar şahamçasy"},
    {"id": 15, "name": "Türkmenbaşy şahamçasy"},
    {"id": 16, "name": "Balkan şahamçasy"},
    {"id": 17, "name": "Altyndag şahamçasy"},
    {"id": 18, "name": "Sanjar şahamçasy"},
    {"id": 19, "name": "Merw şahamçasy"},
    {"id": 20, "name": "Pendi şahamçasy"},
    {"id": 21, "name": "Lebap şahamçasy"},
    {"id": 22, "name": "Amul şahamçasy"},
    {"id": 23, "name": "Kerki şahamçasy"},
    {"id": 24, "name": "Daşoguz şahamçasy"}
]

export {
    branchinfos,
    dataPhones,
}