
import { createSlice, PayloadAction  } from "@reduxjs/toolkit";
import { logout, signIn, signUp } from "../actioncreators/auth";


const initialState: ISlices.IAuthInitialState = {
  isAuth: false,
  error: "",
  isPerson: true,
  emailVerify: false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsPerson(state: {isPerson: boolean}, action: PayloadAction<boolean>) {
      state.isPerson = action.payload;
    },
    setIsAuth(state: {isAuth: boolean}, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setError(state: {error: string}, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setEmailVerify(state: {emailVerify: boolean}, action: PayloadAction<boolean>) {
      state.emailVerify = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.error = "";
      })
      .addCase(signIn.fulfilled, (state) => {
        state.error = "";
        state.isAuth = true;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isAuth = false;
        state.error = action.payload as string;
      })
      .addCase(logout.pending, (state) => {
        state.error = "";
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuth = false;
      })
      .addCase(logout.rejected, (state) => {
        state.isAuth = false;
      })
      .addCase(signUp.pending, (state) => {
        state.error = "";
      })
      .addCase(signUp.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});


export const { setIsAuth, setError, setIsPerson, setEmailVerify} = AuthSlice.actions
export default AuthSlice.reducer
