import { CheckCircle, Warning } from "@mui/icons-material"
import { Button, Dialog, DialogContent, StyledEngineProvider, useMediaQuery, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import store from "../../.."
import { siteSlice } from "redux/reducers/SiteSlice"



export class alertModal {
    static success(message, okFunc, body = '', maxWidth = 'sm') {
        if (okFunc && typeof okFunc === 'function') {
            store.dispatch(siteSlice.actions.setAlertModal({
                type: 'success',
                open: true,
                message,
                okFunc,
                body,
                maxWidth
            }))
        }
        else {
            store.dispatch(siteSlice.actions.setAlertModal({
                type: 'success',
                open: true,
                message,
                okFunc: null,
                body,
                maxWidth
            }))
        }
    }
    static error(message, okFunc, body = '', maxWidth = 'sm') {
        if (okFunc && typeof okFunc === 'function') {
            store.dispatch(siteSlice.actions.setAlertModal({
                type: 'error',
                open: true,
                message,
                okFunc,
                body, maxWidth
            }))
        }
        else {
            store.dispatch(siteSlice.actions.setAlertModal({
                type: 'error',
                open: true,
                message,
                okFunc: null,
                body,
                maxWidth
            }))
        }
    }
}

const properties = {
    success: {
        classNames: {
            message: 'text-green-600',
            button: 'bg-green-600 hover:bg-green-800 text-white cursor-pointer'
        },
        icon: <CheckCircle className='text-green-600' style={{ fontSize: '10rem' }} />
    },
    error: {
        classNames: {
            message: 'text-red-600',
            button: 'bg-red-600 hover:bg-red-800 text-white cursor-pointer'
        },
        icon: <Warning className='text-red-600' style={{ fontSize: '10rem' }} />
    }
}




const AlertModal = () => {
    const open = useSelector(state => state.site.alertModal.open)
    const message = useSelector(state => state.site.alertModal.message)
    const type = useSelector(state => state.site.alertModal.type)
    const okFunc = useSelector(state => state.site.alertModal.okFunc)
    const body = useSelector(state => state.site.alertModal.body)
    const maxWidth = useSelector(state => state.site.alertModal.maxWidth)

    const dispatch = useDispatch()
    const handleClose = () => {
        if (okFunc && typeof okFunc === 'function') {
            okFunc()
        }
        dispatch(siteSlice.actions.setShowAlertModal(false))
        setTimeout(() => {
            dispatch(siteSlice.actions.setAlertModal({
                open: false,
                type: type,
                message: message,
                okFunc: null,
                maxWidth: 'sm'
            }))

        }, 500)
    }
    const theme = useTheme()
    const small = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <StyledEngineProvider injectFirst>
            <Dialog
                open={open}
                // onClose={close}
                // classes={{ root: 'backdrop-blur-sm', paper: 'shadow-none bg-opacity_g', }}
                classes={{ root: `backdrop-blur-sm`, paper: 'shadow-none', }}
                // sx={{ backgroundColor: 'rgba(0,0,0, 0.1)!important' }}
                fullWidth={true}
                maxWidth={maxWidth}
                fullScreen={small}
                BackdropProps={{
                    classes: { root: 'bg-dialog-opacity-1' }
                }}

            >
                <DialogContent>
                    <div className="text-center">
                        {properties[type].icon}
                    </div>
                    <p className={`${properties[type].classNames.message} text-center text-2xl mt-3`}>
                        {message}
                    </p>
                    <div className="py-3">
                        {body}
                    </div>
                    <div className="text-center mt-6">
                        <Button size='large' className={`${properties[type].classNames.button} duration-300`} type='button' onClick={handleClose} variant='contained' color={type}>Ok</Button>

                    </div>
                </DialogContent>
            </Dialog>
        </StyledEngineProvider>
    )
}





export default AlertModal