const ru: any = {
  // loader
  'loading-main': "Просим вас подождать данные загружаются...",
  // auth Header
  "online reception": "Онлайн приёмная",
  "Individual": 'Физическое лицо',
  "Entity": "Юридическое лицо",
  // auth footer
  "phone": "телефон",
  "Address": "Адрес",
  "All right reserved": "Все права защищены. Государственный коммерческий банк Туркменистана «Туркменистан»",
  "slogan": "Создайте свое будущее вместе с нами!",
  "address_of_the_bank": "74400, город Ашхабад, улица Гороглы, 79",
  // Login page
  "Sign in": "Вход",
  "Sign up": "Зарегистрироваться",
  "Forgot password": "Забыли пароль",
  "Enter": "Войти",
  "Username": "Имя пользователя",
  "Password": "Пароль",
  "Invalid grand": "Неправильное имя пользователя или пароль",

  // register page
  "success registered": "Вы успешно зарегистрировались",
  "user has already registered": "Пользователь с таким именем уже существует",
  "email has already registered": "Пользователь с такой электронной почтой уже существует",
  "Is not same passwords": "Пароли не совпадают",
  "Minimum 8 symbol": "Минимум 8 символов",
  "Name": "Имя",
  "Surname": "Фамилия",
  "Phone number": "Телефонный номер",
  "Email": "Email",
  "Input email correctly": "Введите еmail корректно",
  "User data": "Данные пользователя",
  "Private data": "Личные данные",
  "Password confirm": "Введите пароль повторно",
  "Required": "Это объязательное поле",
  "Back": "Назад",
  "register disabled": "ЮРИДИЧЕСКИМ ЛИЦАМ НЕОБХОДИМО СВЯЗАТЬСЯ С БАНКОМ ДЛЯ ПОДКЛЮЧЕНИЯ ДАННОЙ УСЛУГИ",
  // request status
  "Successfully sent": "Успешно отправлено",
  "filled inputs": "Заполненные поля",
  // room header
  "Call-center": "Справочная",
  "Profile": "Профиль",
  "Change password": "Изменить пароль",
  "Logout": "Выйти",
  "Are you sure to exit": "Хотите выйти?",
  // room
  "Welcome": 'Добро пожаловать',
  // maintable
  "Type": 'Тип',
  "Date": 'Дата создания',
  "Status": "Статус",
  "Action": "Действие",
  "Per page": "На каждой странице",
  "Rejected": "Отказано",
  "Done": "Готов",
  "Success": "Успешно",
  "Pending": "Ожидание",
  // ViewItem
  "Info": "Информация",
  "Notes": "Заметки",
  "type of document": "Тип документа",
  "type of card": "Тип карты",
  "type of credit": "Тип кредита",
  "By who": "Имя заявителя",
  "Is hurry": 'Услуга "Срочно"',
  "With delivery": 'Услуга "Доставка"',
  "Yes": "Да",
  "No": "Нет",
  "Max credit amount": "Максимальная сумма кредита",
  "Credit amount": "Сумма кредита",
  "Credit percentage": "Процентная ставка",
  "Credit term": "Срок кредита",
  "Credit repayment base": "Обеспечение возврата долга",
  "credit repayment sources": "Источники погашения долга",
  "credit repayment ways": "Способы погашения долга",
  "No note": "Заметок нет",
  //steps
  "Select type of card": "Выберите тип карты",
  "Input private data": "Данные заказчика",
  "Card data": "Данные для получение карты",
  "Extra data": "Дополнительные данные",
  "Select payment type": "Выберите тип оплаты",
  // private date
  "Input name correctly": "Введите имя корректно",
  "Input surname correctly": "Введите фамилию корректно",
  "Input thirdname correctly": "Введите отчество корректно",
  "Input birthdate correctly": "Введите дату рождения корректно",
  //
  "Thirdname": "Отчество",
  "Birthdate": "Дата рождение",
  "Passport serie": "Паспортная серия",
  "Passport given date": "Дата выдачи паспорта",
  "Passport given place": "Место выдачи паспорта",
  "Gender": "Пол",
  "Male": "Мужской",
  "Female": "Женский",
  "male": "Мужской",
  "female": "Женский",
  "Turkmen": "Туркмен",
  "turkmen": "Туркмен",
  "Other": "Другое",
  "Nationality": "Национальность",
  "0": "Нет",
  "1": "Да",
  "false": "Нет",
  "true": "Да",
  /////////////////////////////çard info
  "Name latin": "Имя (латинскими буквами)",
  "Surname latin": "Фамилия (латинскими буквами)",
  "Input latin": "Только латинскими буквами",
  "sms connection": "СМС подключение",
  "Home phone": "Домашний телефон",
  "Birth place": "Место рождения",
  "Code word to define": "Кодовое слово для вашей идентификации при обращении в банк",
  "Secret word": "Кодовое слово",
  "Note": "Заметка",
  "Select branch": "Выберите головной банк или отделение банка",
  "Services": "Услуги",
  "Next": "Вперед",
  "Card payment": "Оплата карты",
  "Summary payment": "Сумма оплаты",
  "Confirm the data": "Подтвердите данные",
  "Branch": "Головной банк или отделение банка",
  "I confirm": "Подтверждаю",
  "Correct": "Исправить",
  ////////////new Credit request
  "Credit data": "Данные для кредита",
  "Credit calculator": "Кредитный калькулятор",
  "Select type of credit": "Выберите тип кредита",
  "year-a": "года",
  "year": "год",
  "years": "лет",
  "monthes": "месяцев",
  "thousand": "тыс.",
  "month": "месяц",
  "month-a": "месяца",
  "monthly payment": "Ежемесячный платёж",
  "Percentage payment": "Процентный платеж",
  "Credit payment": "Платеж для погашения основной суммы",
  "Need salary for credit": "Доход",
  "Credit requirement": "Обеспечение возврата долга",
  "credit calc notice 1": "Размер ежемесячного платежа определяется сроком кредита",
  "credit calc notice 2": "Расчёт был сделан с таким учетом: 365 дней в 1 году и 30 дней в 1 месяце в среднем",
  "Salary or family income": "Сумма месячной зарплаты или дохода семьи",
  "Can input salary or family income": "Может быть введен сумма месячной зарплаты или дохода семьи",
  "Work position": "Должность",
  "Work experience": "Стаж с последней месты работы",
  "Work place": "Место работы",
  ///////////////request result/////////////
  'Success requested': "Ваша заявка успешно принята",
  "Some error, try it again": "Ошибка, повторите попытку",
  "Notice you about website": 'Сообщаем Вам, что перечень документов размещен на официальном сайте Акционерно-Коммерческого Банка «Рысгал». Чтобы перейти на страницу',
  "follow the link": "перейдите по этой ссылке",
  "Responsible staff": "Ответственный за вас сотрудник",
  "Necessary docs": "Необходимые документы",
  "Bank address": "Адрес банка",
  "Room location": "Местонахождение кабинета",
  "Floor": "Этаж",
  "Room": "Кабинет",
  /////////////////new document request
  "Online service payment": "Onlaýn hyzmat",
  "Is bussinessman": "Telekeçimi",
  "Select type of document": "Выберите тип документов",
  "Document data": "Данные для документа",

  //////////////////EmptyData.js
  "Yuzlenme yok": "Нет заявок",
  ////////////////////HomePhone.js
  "Input home phone correctly": "Введите домашний телефон правильно",
  ///////////////////////Nationality.js
  "Input nationality correctly": "Введите национальность правильно",
  ///////////////////////////PassportGiýenDate.js
  "Проверьте правильность даты выдачи": "Проверьте правильность даты выдачи",
  "Дата выдачи не может быть в будущем": "Дата выдачи не может быть в будущем",
  ///////////////////////PassportGivenPlace
  "Input passport given place correctly": "Введите правильное место в паспорте",
  ////////////////////////////Passport number
  "Input passport serie correctly": "Введите серию паспорта правильно",
  //////////////////////////////EmailÝerify.js
  "Açary çalyşmak üçin ssylka email-e ugradyldy": "Ссылка отправлена ​​на почту для смены пароля",
  "Email-i dogry giriziň": "Введите адрес электронной почты правильно",
  "Açary dikeltmek": "Сброс пароля",
  "Email dogry girizin": "Введите адрес электронной почты правильно",
  "Ýapmak": "Закрыть",
  "Ugratmak": "Отправить",
  ///////////////////////Foooter.js
  "Geljegiňizi biz bilen dörediň!": "Создавай будущее вместе с нами!",
  ///////////////////CardInfoCheck.js
  "Informations": "Информация",
  "Töleg maglumatlary": "Информация об оплате",
  "Agree to payment policy": "Согласен с политикой оплаты",
  ///////////////////////CardSelect.js
  "Hasaplaşyk walýutasy": "Рассетная валюта",
  ////////////////CreditCalc.js
  "Karzyň möhletine görä her aýdaky tölegiň möçberi kesgitlenýär": "Размер ежемесячного платежа определяется сроком кредита",
  "1 ýylda 365 gün we ortaça 1 aýda 30 gün hasaby bilen": "Рассчитан с таким учетом: 365 дней в году и 30 дней в месяце",
  /////////////////////CreditInfoCheck.js
  "Karz maglumatlary": "Информация о кредите",
  "Agree to private policy": "Согласен с политикой конфиденциальности",
  //////////////////////CreditInfoSection.js
  "Not enough salary or family income": "Не хватает зарплаты или дохода семьи",
  "Not enough experience": "Недостаточно опыта",
  ///////////////////////////////////Header
  "Home page": "Главная",

  //////////////////////////////Sidebar
  "Card section": "Получить карту",
  "Document section": "Получить справку",
  "Feedback section": "Жалобы и предложения",
  "profile": "Профиль",
  "Credit section": "Получить кредит",
  /////////////////////////Restore.jsx
  "Açar üstünlikli dikeldildi": "Ключ успешно восстановлен",
  "Näsazlyk, dikeltme wagty tamamlandy": "Проблема, время восстановления истекло",
  "Email-i dogry girizin": "Введите адрес электронной почты правильно",
  "Näsazlyk, täzeden barlap görüň": "Ошибка, попробуйте повторно",
  "Gizlin açar söz gaýtalap": "Повторно введите пароль",
  "Açarlar deň gelenok": "Ключи не совпадают",
  "Dikeltmek": "Восстановить",
  ///////////////////////////////////pages/Room/Card
  "Kart almak üçin onlaýn ýüzlenme": "Онлайн заявка для получения карты",
  "Ýüzlenme ugratmak": "Отправить заявку",
  ////////////////////////pages/Room/Credit
  "Karz almak üçin onlaýn ýüzlenme": "Онлайн заявка для получения кредита",
  //////////////////////////////////////////pages/Room/Document.jsx
  "Güwanama üçin onlaýn ýüzlenme": "Онлайн заявка на документ",
  /////////////////////////////pages/Room/Profile/Offers
  "Offered cards": "Заявки на карту",
  "Offered credits": "Заявки на кредит",
  "Offered documents": "Заявки на документ",

  //////////////////////////////pages/Room/passwordChange
  "old password": "Старый пароль",
  "new password": "Новый пароль",
  "new password confirm": "Новый пароль повторно",
  "Change": "Изменить",

  "easy code": "Лёгкий пароль",
  "middle code": "Средний пароль",
  "hard code": "Сложный пароль",
  "input data is incorrect": "Введены неправильные данные",
  "password successfully changed": "Пароль успешно изменена",
  "some advices for password": "Некоторые советы",
  "at least one uppercase": "Пароль должен содержать как минимум 1 букву в верхнем регистре (A-Z)",
  "at least one number": "Пароль должен содержать как минимум 1 цифру (0-9)",
  "at least eight charecter": "Пароль должен содержать как минимум 8 символов",

  ////////////////////////////pages/Room/feedback
  "Feedback type": "Выберите тип заявки",
  "complain": "Жалоба",
  "recommend": "Предложение",
  "Your text": "Ваш текст",
  ////////////////////////////pages/Room/feedback
  "Select department": "Выберите отдел",
  "Meeting purpose": "Тема встречи",
  "Select your free date": "Выберите дату посещение банка",
  "Meet time": "Выбранная дата",
  /////////////////////////////////////////
  "New request": "Новая заявка",
  "Profile Edit": "Изменить",
  "MiddleName": "Отчество",
  "ChooseRegion": "Выберите отделение вашего банка",
  "Edit Profile": "Изменить профиль",

  "Add MoreInfo": "Добавить дополнительную информацию",
  "Save MoreInfo": "Сохранить дополнительную информацию",
  "Save Changes": "Сохранить изменения",
  "Message from organization": "Письменное обращение от организации",
  "mary": "Мары",
  "balkan": "Балкан",
  "dasoguz": "Дашогуз",
  "ashgabat": "Ашгабат",
  "lebap": "Лебап",
  "Online Payment": "Онлайн оплата",
  "Total Amount": "Итоговая сумма",
  "Passport": "Пасспорт",
  "Birth date": "День рождения",
  "PrivateAutoFill": "Ваши данные заполнены автоматически но вы можете их изменить",
  "ImageChangeHelpText": "Нажмите на  '+' чтобы прикрепить скан вашего письма с обращением к банку",
  "ScanAreaMove": "Переместите скан сюда",
  "supportScanType": "Поддерживаемый формат",
  "writeYourMessage": "Введите своё сообщение",
  "originNumber": "Исходящий номер",
  "scanDate": "Выберите дату заявки",
  "notEntered": "Не задано",
  "dataSuccesChanged": "Данные успешно изменены",
  "dataSuccesSaved": "Данные вашего профиля успешно сохранены",
  "Error": "Ошибка",
  "PleaseFillYourProfileData": "Пожалуйста заполните дополнительные данные вашего профиля",
  "Fill": "Заполнить",
  "noNotifications": "У вас пока нет никаких уведомлений",
  "uploadMaxSizeError": "Ошибка. Размер файла привысил лимит в 2 мегабайт. Пожалуйста выберите файл который весит меньше 2 мегабайт",
  "fileErrorTryAgain": "Ошибка. При попытке удалить файл",
  "attention": "Внимание",
  "descriptionFillProfile": "Настоятельно рекомендуем вам заполнить ваши данные профиля для автоматического заполнения при разных действиях к личном кабинете вы можете проигнорировать это сообщение и нажать 'Продолжить' тогда вы сможете самостоятельно вручную заполнить данные без автозаполнения полей.",
  "fillOutData": "Заполнить данные",
  "continue": "Продолжить",
  "loading": "Загрузка",
  "faceType5": "Физическое лицо",
  "faceType1": "Юридическое лицо",
  "check": "Проверить",
  "sentAgain": "Отправить ещё",
  "WeSentOnYourEmailMessage": "Мы отправили на вашу почту",
  "MessageForEdentify": "сообщение, подтвердите вашу личность чтобы продолжить",
  "YourEmailIsNotFerfied": "Ваша почта не подтверждена!",
  "anotherEmailMessageSented": "Повторное сообщение на почту отправлено",
  "emailSentVerifyError":"Произошла ошибка при отправке повтороного сообщения",
  "noVerifiedEmail": "Требуется подтверждение почты",
  "verifiedEmail": "Почта подтверждена",
  "GivenDateCantBeAFuture" : "Дата выдачи не может быть в будущем",
  "Add": "Добавить",
  "youEmailSuccesVerified": "Ваша почта успешно подтверждена",
  "errorVerifyEmail": "Ошибка подтверждения почты. Пожалуйста проверьте вашу почту",
  "succesVerifyEmail": "Поздравляем вы успешно подтвердили почту",
  "CreateDoc": "Создать заявку",
  "EmailVerifiedProfile": "Подтверждение почты",
  "ru": "Русский",
  "en": "English",
  "tm": "Turkmen",
  "step": "Шаг"
}
export default ru