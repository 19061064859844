import { langData } from './../data/lang';
import { apiPrivateService, apiPublicService } from "api/api"
import { config } from "config"
import { dataPhones } from "data/regions"

const getRegions = () => {
    return apiPublicService.get(`${config.apiURL}/api/branchinformations`)
}

const getUser = () => {
    return apiPrivateService.get(`${config.apiURL}/api/getUser`)
}

const compareById = (idToCompare: number | null) => {
    let region: { phones: string[], name: string } = { phones: [], name: "" }
    const foundOffice = dataPhones.find(phone => phone.id === idToCompare);

    if (foundOffice) {
        return region = {
            ...region,
            name: foundOffice.name,
            phones: foundOffice.number,
        }
    }

    return region = {
        ...region,
        name: "",
        phones: [],
    }
}

const getFlag = ({ lang, langData }: { lang: string, langData: { logo: string, value: string }[]}) => {
    let flag: { value: string } = { value: "" }

    switch (lang) {
        case "tm":
            return flag = {
                value: langData[0].value,
            }
        case "ru":
            return flag = {
                value: langData[1].value,
            }
        case "en":
            return flag = {
                value: langData[2].value,
            }
    }
    return flag
}

const formatBytes = (bytes: number) => {
    if (bytes < 1024) {
        return bytes + ' б';
    } else if (bytes < 1048576) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else {
        return (bytes / 1048576).toFixed(2) + ' MB';
    }
}

const getMaxDay = () => {
    const today = new Date();
    today.setDate(today.getDate()); // Добавляем 5 дней к текущей дате
    return today;
};

const getMinDay = () => {
    const today = new Date();
    today.setDate(today.getDate() - 5); // Добавляем 5 дней к текущей дате
    return today;
};

export {
    getRegions,
    getUser,
    getFlag,
    compareById,
    formatBytes,
    getMaxDay,
    getMinDay,
}