import { createSlice } from "@reduxjs/toolkit"
import { fetchSiteInfo } from "../actioncreators/site"

const initialState = {
    siteInfo: [],
    isLoading: false,
    registerLoading: false, 
    sidebar: {
        open: true,
        minimize: false
    },
    newRequest: {
        open: false,
        type: null
    },
    alertModal: {
        open: false,
        type: 'success',
        message: '',
        okFunc: null,
        body:''
    }
}

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setIsLoading(state: {isLoading : boolean}, action) {
            state.isLoading = action.payload
        },
        setRegisterLoading(state: {registerLoading : boolean}, action) {
            state.registerLoading = action.payload
        },
        setAlertModal(state, action) {
            state.alertModal = action.payload
        },
        setShowAlertModal(state,action){
            state.alertModal.open = action.payload
        },
        // sidebar
        setShowSidebar(state, action) {
            state.sidebar.open = action.payload
        },
        setSidebarMin(state, action) {
            state.sidebar.minimize = action.payload
        },
        // new request
        setShowNewRequest(state, action) {
            state.newRequest.open = action.payload
        },
        setNewRequestType(state, action) {
            state.newRequest.type = action.payload
        }

    },
    extraReducers: {
        [fetchSiteInfo.pending.type]: (state) => {
            // state.isLoading = true
        },
        [fetchSiteInfo.fulfilled.type]: (state, action) => {
            state.siteInfo = action.payload
            // state.isLoading = false

        },
        [fetchSiteInfo.rejected.type]: (state, action) => {
            // console.log(action.payload)
            // state.isLoading = false
        }
    }
})


export default siteSlice.reducer