
import { createSlice, PayloadAction  } from "@reduxjs/toolkit";

const initialState: ISlices.IRegionInitialState = {
  region: {
    name: "",
    phones: [],
  }
};

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRegion(state: {region: {name: string, phones: string[]}}, action: PayloadAction<{name: string, phones: string[]}>) {
      state.region = action.payload;
    },
  },
});


export const { setRegion } = regionSlice.actions
export default regionSlice.reducer;
