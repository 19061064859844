import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEmailVerify } from 'redux/reducers/AuthSlice';
import { setRegion } from 'redux/reducers/RegionSlice';
import { setUserInfo, setUserLoading } from 'redux/reducers/UserSlice';
import { compareById, getUser } from 'utils'

const Watchers = () => {
  const dispatch = useDispatch();
  const { userInfo, userLoading } = useSelector((state: { userInfo: { userInfo: IUtils.IGetUserData, userLoading: boolean } }) => state.userInfo);

  React.useEffect(() => {
    dispatch(setUserLoading(true))
    getUser().then(({ data }) => {
      dispatch(setUserInfo(data))
      dispatch(setEmailVerify(true))
      dispatch(setUserLoading(false))
    }).catch(err => {
      dispatch(setUserLoading(false))
      if (err.message === "Request failed with status code 400") {
        dispatch(setEmailVerify(false))
      }
    })
  }, [])

  React.useEffect(() => {
    if (userLoading === false) {
      try {
        let userBranchId = userInfo.branch_id;
        userBranchId = userBranchId === null ? 49 : userBranchId;
        const { phones, name } = compareById(userBranchId)
        dispatch(setRegion({ name: name, phones: phones }))
      } catch (error) {
        dispatch(setRegion({ name: "", phones: [""] }))
      }
    }
  }, [userLoading])


  return <></>
}

export default Watchers