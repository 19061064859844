import { Backdrop, LinearProgress ,Box} from "@mui/material"
import {useTranslation} from 'react-i18next'


const Loader = () => {
    const {t} = useTranslation()
    return (
        <Backdrop
            sx={{ backgroundColor: 'rgba(0,0,0, 0.1)!important', zIndex: '9999999!important' }}
            className='backdrop-blur-sm'
            open={true}
        >
            {/* <div className="w-full h-full p-5"> */}
            <div className="p-8 rounded-lg bg-white w-9/12 md:w-1/2 border border-blue-200">
                <Box sx={{ width: '100%' }}>
                    <LinearProgress valueBuffer={100} classes={{root:'bg-blue-200'}}/>
                </Box>
                <div className='mt-5 text-xl text-center text-gray-600'>
                    {t('loading-main')}
                </div>
            </div>
            {/* </div> */}
        </Backdrop>
    )
}


export default Loader