import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPublicService } from "../../api/api";
import { siteSlice } from '../reducers/SiteSlice'
import Cookies from 'js-cookie'
import { alertModal } from "../../components/common/AlertModal/AlertModal";
import { AuthSlice } from "../reducers/AuthSlice.ts";
import { RoutesNames } from "../../routes/routes";





export const fetchSiteInfo = createAsyncThunk(
    'site/fetchSiteInfo',
    async (_, thunkAPI) => {
        try {
            const response = await apiPublicService.get('/siteinformations')
            if(response.status === 200){
                return response.data?.data
            }
        }
        catch (e) {
            // debugger
            return thunkAPI.rejectWithValue(e.message||"Unknown error")
        }

    }
)



export const refreshToken = createAsyncThunk(
    'site/refreshToken',
    async (req, thunkAPI) => {
        thunkAPI.dispatch(siteSlice.actions.setIsLoading(true))
        // debugger
        try {
            const response = await apiPublicService.post('/oauth/token', {
                refresh_token: Cookies.get('refresh_token'),
                grant_type: "refresh_token",
            })
            if (response.status === 200 && response.data) {
                // debugger
                Cookies.set('access_token', response.data.access_token)
                Cookies.set('refresh_token', response.data.refresh_token)
                thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
                thunkAPI.dispatch(AuthSlice.actions.setIsAuth(true))
                thunkAPI.dispatch(req.action(req.data))
            }
        }
        catch (e) {
            
            if(e.response && e.response.status === 401){
                Cookies.remove('access_token')
                Cookies.remove('refresh_token');
                thunkAPI.dispatch(AuthSlice.actions.setIsAuth(false))
                thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
                window.location.href = RoutesNames.LOGIN

            }
            else {
                thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
                alertModal.error(req.error||i18n.store.data[i18n.language].translation['Some error, try it again'], () => {
                    Cookies.remove('access_token')
                    Cookies.remove('refresh_token');
                    thunkAPI.dispatch(AuthSlice.actions.setIsAuth(false))
                    window.location.href = RoutesNames.LOGIN
                })
                Cookies.remove('access_token')
                Cookies.remove('refresh_token');
                thunkAPI.dispatch(AuthSlice.actions.setIsAuth(false))
            thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))

                return thunkAPI.rejectWithValue(e.message);

            }


        }
        finally{
            thunkAPI.dispatch(siteSlice.actions.setIsLoading(false))
            // thunkAPI.dispatch(dataSlice.actions.setIsLoading(false))

        }
    }
)