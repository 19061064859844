import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutesNames } from "../../../routes/routes";

const AuthRoute = () => {
  const isAuth = useSelector((state: any) => state.auth.isAuth);
  const location = useLocation();
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesNames.INDEX} state={{ from: location }} replace />
  );
};

export default AuthRoute;
