import { combineReducers, configureStore } from "@reduxjs/toolkit"
import siteReducer from './reducers/SiteSlice'
import authReducer from './reducers/AuthSlice'
import dataReducer from "./reducers/DataSlice"
import userInfoSlice from "./reducers/UserSlice"
import regionSlice from "./reducers/RegionSlice"





const rootReducer = combineReducers({
    site: siteReducer,
    auth: authReducer,
    data: dataReducer,
    userInfo: userInfoSlice,
    region: regionSlice,
})


export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}
