const tm = {
  // loader
  'loading-main': "Biraz garaşyň maglumatlar ýüklenýär...",
  // auth Header
  "online reception": "Onlaýn Kabulhana",
  "Individual": 'Fiziki şahs',
  "Entity": "Ýurdiki şahs",
  // auth footer
  "phone": "telefon",
  "Address": "Salgy",
  "All right reserved": "Ähli hukuklar goralan. Türkmenistanyň «Türkmenistan» döwlet täjirçilik banky",
  "slogan": "Geljegiňizi biz bilen dörediň!",
  "address_of_the_bank": "744000, Aşgabat şäheriniň Görogly köçesi, 79",
  // Login page
  "Sign in": "Ulgama girmek",
  "Sign up": "Agza bolmak",
  "Forgot password": "Açar söz ýatdan çykdymy",
  "Enter": "Girmek",
  "Username": "Ulanyjynyň ady",
  "Password": "Gizlin açar sözi",
  "Invalid grand": "Ulanyjynyň ady ýa-da gizlin açar sözi nädogry",
  // register page
  "success registered": "Üstünlikli hasaba alyndyňyz",
  "user has already registered": "Bu ulanyjy eýýäm hasaba alnan",
  "email has already registered": "Bu elektron poçta eýýäm hasaba alnan",
  "Is not same passwords": "Açarlar deň gelenok",
  "Minimum 8 symbol": "Azyndan 8 simwol",
  "Name": "Ady",
  "Surname": "Familiýasy",
  "Phone number": "Telefon belgisi",
  "Email": "Email",
  "Input email correctly": "Email-i dogry giriziň",
  "User data": "Ulanyjynyň maglumatlary",
  "Private data": "Şahsy maglumatlar",
  "Password confirm": "Gizlin açar sözi gaýtalaň",
  "Required": "Bu hökmany",
  "Back": "Yza",
  "register disabled": "Ýuridiki taraplar bu hyzmaty birikdirmek üçin banka ýüz tutyp bilerler",
  // request status
  "Successfully sent": "Üstünlikli ugradyldy",
  "filled inputs": "Doldurlan maglumatlar",
  // room header
  "Call-center": "Sorag-jogap",
  "Profile": "Profaýl",
  "Change password": "Gizlin açary çalyşmak",
  "Logout": "Çykmak",
  "Are you sure to exit": "Siz çykmakçymy?",

  // room
  "Welcome": 'Hoş geldiňiz',
  // maintable
  "Type": 'Görnüşi',
  "Date": 'Döredilen senesi',
  "Status": "Ýagdaýy",
  "Action": "Amal",
  "Per page": "Her sahypada",
  "Rejected": "Goýbolsun",
  "Done": "Taýyn",
  "Success": "Tamamlandy",
  "Pending": "Garaşylýar",
  // ViewItem
  "Info": "Maglumat",
  "Notes": "Bellikler",
  "type of document": "Güwanamanyň görnüşi",
  "type of card": "Kartyň görnüşi",
  "type of credit": "Karzyň bölümi",
  "By who": "Kim tarapyndan",
  "Is hurry": "Gyssaglymy",
  "With delivery": "Eltip berme hyzmatyndan peydalanan",
  "Yes": "Hawa",
  "No": "Ýok",
  "Max credit amount": "Karz möçberi",
  "Credit amount": "Karz möçberi",
  "Credit percentage": "Karz göterimi",
  "Credit term": "Karzyň möhleti",
  "Credit repayment base": "Karzy gaýtarmagyň üpjünçiligi",
  "credit repayment sources": "Karzy üzmegiň çeşmeleri",
  "credit repayment ways": "Karzy üzmegiň çeşmeleri",
  "month": "aý",
  "No note": "Bellik ýok",
  //steps
  "Select type of card": "Kartyň görnüşini saýlaň",
  "Input private data": "Şahsy maglumatlar",
  "Card data": "Kart almak üçin maglumatlar",
  "Extra data": "Goşmaça maglumatlar",
  "Select payment type": "Tölegiň görnüşini saýlaň",
  // private date
  "Input name correctly": "Adyňyzy dogry giriziň",
  "Input surname correctly": "Familiýaňyzy dogry giriziň",
  "Input thirdname correctly": "Ataňyzyň adyny dogry giriziň",
  "Input birthdate correctly": "Doglan senäňizi dogry giriziň",
  //
  "Thirdname": "Atasynyň ady",
  "Birthdate": "Doglan senesi",
  "Passport serie": "Pasport belgisi",
  "Passport given date": "Pasportyň berlen senesi",
  "Passport given place": "Pasportyň berlen ýeri",
  "Gender": "Jynsy",
  "Male": "Erkek",
  "Female": "Aýal",
  "male": "Erkek",
  "female": "Aýal",
  "Turkmen": "Türkmen",
  "Other": "Başga",
  "Nationality": "Milleti",
  "0": "Ýok",
  "1": "Hawa",
  "false": "Ýok",
  "true": "Hawa",
  "turkmen": "Türkmen",
  /////////////////////////////çard info
  "Name latin": "Ady (latyn harplary bilen)",
  "Surname latin": "Familiýasy (latyn harplary bilen)",
  "Input latin": "Diňe latyn harplary bilen",
  "sms connection": "Sms birikdirme",
  "Home phone": "Öý telefony",
  "Birth place": "Doglan ýeri",
  "Code word to define": "Bank bilen habarlaşanyňyzda siziň anyklanmagyňyz üçin kod sözi",
  "Secret word": "Gizlin söz",
  "Note": "Bellik",
  "Select branch": "Baş bank ýa-da bankyň şahamçasyny saýlaň",
  "Services": "Hyzmatlar",
  "Next": "Öňe",
  "Card payment": "Kartyň tölegi",
  "Summary payment": "Umumy töleg",
  "Confirm the data": "Maglumatlary tassyklamak",
  "Branch": "Baş bank ýa-da bankyň şahamçasy",
  "I confirm": "Tassyklaýaryn",
  "Correct": "Düzetmek",
  ////////////new Credit request
  "Credit data": "Karz almak üçin maglumatlar",
  "Credit calculator": "Karz hasaplaýjy",
  "Select type of credit": "Karzyň görnüşini saýlaň",
  "year-a": "ýyl",
  "year": "ýyl",
  "years": "ýyl",
  "monthes": "aý",
  "month-a": "aý",
  "thousand": "müň",
  "monthly payment": "Aýdaky töleg",
  "Percentage payment": "Göterim üçin töleg",
  "Credit payment": "Karzyň esasy bergisiniň töleg möçberi",
  "Need salary for credit": "Girdeji",
  "Credit requirement": "Karzyň üpjünçiligi",
  "credit calc notice 1": "Karzyň möhletine görä her aýdaky tölegiň möçberi kesgitlenýär",
  "credit calc notice 2": "1 ýylda 365 gün we ortaça 1 aýda 30 gün hasaby bilen",
  "Salary or family income": "Aýlyk zähmet hakynyň ýa-da maşgala girdejisiniň möçberi",
  "Can input salary or family income": "Aýlyk zähmet haky ýa-da maşgala girdejisi girizilip bilner",
  "Work position": "Wezipesi",
  "Work experience": "Soňky iş ýeri boýunça tejribesi",
  "Work place": "Iş ýeri",
  ///////////////request result/////////////
  'Success requested': "Siziň ýüztutmaňyz kabul edildi",
  "Some error, try it again": "Käbir ýalňyşlyk täzeden synanyşyp görüň",
  "Notice you about website": '"Rysgal" paýdarlar täjirçilik bankynyň internet sahypasynda resminamalaryň sanawy ýerleşdirilendigini Siziň dykgatyňyza ýetirýäris. Sahypa geçmek üçin',
  "follow the link": "şu ýere basyň",
  "Responsible staff": "Size jogapkär işgär",
  "Necessary docs": "Gerekli resminamalar",
  "Bank address": "Bankyň salgysy",
  "Room location": "Siziň barmaly otagyňyz",
  "Floor": "Gat",
  "Room": "Otag",
  /////////////////new document request
  "Online service payment": "Onlaýn hyzmat",
  "Is bussinessman": "Telekeçimi",
  "Select type of document": "Güwanamanyň görnüşini saýlaň",
  "Document data": "Güwanama üçin maglumatlar",

  //////////////////EmptyData.js
  "Yuzlenme yok": "Ýüzlenme ýok",
  ////////////////////HomePhone.js
  "Input home phone correctly": "Öý telefon belgiňizi dogry giriziň",
  ///////////////////////Nationality.js
  "Input nationality correctly": "Milletiňizi dogry giriziň",
  ///////////////////////////PassportGiýenDate.js
  "Проверьте правильность даты выдачи": "Berlen senäniň dogrulygyny barlaň",
  "Дата выдачи не может быть в будущем": "Berlen sene öňümizde",
  ///////////////////////PassportGivenPlace
  "Input passport given place correctly": "Passportyň berlen ýerini dogry giriziň",
  ////////////////////////////Passport number
  "Input passport serie correctly": "Passport belgisini dogry giriziň",
  //////////////////////////////EmailÝerify.js
  "Açary çalyşmak üçin ssylka email-e ugradyldy": "Açary çalyşmak üçin ssylka email-e ugradyldy",
  "Email-i dogry giriziň": "Email-i dogry giriziň",
  "Açary dikeltmek": "Açary dikeltmek",
  "Email dogry girizin": "Email dogry girizin",
  "Ýapmak": "Ýapmak",
  "Ugratmak": "Ugratmak",
  ///////////////////////Foooter.js
  "Geljegiňizi biz bilen dörediň!": "Geljegiňizi biz bilen dörediň!",
  ///////////////////CardInfoCheck.js
  "Informations": "Maglumatlar",
  "Töleg maglumatlary": "Töleg maglumatlary",
  "Agree to payment policy": "Tölegiň düzgün-kadalary bilen ylalaşýaryn",
  ///////////////////////CardSelect.js
  "Hasaplaşyk walýutasy": "Hasaplaşyk walýutasy",
  ////////////////CreditCalc.js
  "Karzyň möhletine görä her aýdaky tölegiň möçberi kesgitlenýär": "Karzyň möhletine görä her aýdaky tölegiň möçberi kesgitlenýär",
  "1 ýylda 365 gün we ortaça 1 aýda 30 gün hasaby bilen": "1 ýylda 365 gün we ortaça 1 aýda 30 gün hasaby bilen",
  /////////////////////CreditInfoCheck.js
  "Karz maglumatlary": "Karz maglumatlary",
  "Agree to private policy": "Hususylyk syýasaty bilen ylalaşýaryn",
  //////////////////////CreditInfoSection.js
  "Not enough salary or family income": "Ýeterlik aýlyk zähmet haky ýa-da maşgala girdejisi ýok",
  "Not enough experience": "Ýeterlik tejribe ýok",
  ///////////////////////////////////Header
  "Home page": "Baş sahypa",
  //////////////////////////////Sidebar
  "Card section": "Kart almak",
  "Document section": "Güwanama almak",
  "Feedback section": "Arz-şikaýat bölümi",
  "profile": "Şahsy maglumatlar",
  "Credit section": "Karz almak",
  /////////////////////////Restore.jsx
  "Açar üstünlikli dikeldildi": "Açar üstünlikli dikeldildi",
  "Näsazlyk, dikeltme wagty tamamlandy": "Näsazlyk, dikeltme wagty tamamlandy",
  "Email-i dogry girizin": "Email-i dogry girizin",
  "Näsazlyk, täzeden barlap görüň": "Näsazlyk, täzeden barlap görüň",
  "Gizlin açar söz gaýtalap": "Gizlin açar söz gaýtalap ýazyň",
  "Açarlar deň gelenok": "Açarlar deň gelenok",
  "Dikeltmek": "Dikeltmek",
  ///////////////////////////////////pages/Room/Card
  "Kart almak üçin onlaýn ýüzlenme": "Kart almak üçin onlaýn ýüzlenme",
  "Ýüzlenme ugratmak": "Ýüzlenme ugratmak",
  ////////////////////////pages/Room/Credit
  "Karz almak üçin onlaýn ýüzlenme": "Karz almak üçin onlaýn ýüzlenme",
  //////////////////////////////////////////pages/Room/Document.jsx
  "Güwanama üçin onlaýn ýüzlenme": "Güwanama üçin onlaýn ýüzlenme",
  ////////////////////////////////pages/Room/Profile/Offers
  "Offered cards": "Berilen  kartlar",
  "Offered credits": "Berilen  karzlar",
  "Offered documents": "Berilen  güwanamalar",

  //////////////////////////////pages/Room/passwordChange
  "old password": "Öňki gizlin açar",
  "new password": "Täze gizlin açar",
  "new password confirm": "Täze gizlin açar gaýtalap",
  "Change": "Üýtgetmek",
  "easy code": "Ýeňil açar",
  "middle code": "Ortaça açar",
  "hard code": "Kyn açar",
  "input data is incorrect": "Maglumatlar nädogry girizildi",
  "password successfully changed": "Gizlin açar üstünlikli üýtgedildi",
  "some advices for password": "Käbir maslahatlar",
  "at least one uppercase": "Azyndan 1 sany bas harp bolmaly (A-Z)",
  "at least one number": "Azyndan 1 sany sifr bolmaly (0-9)",
  "at least eight charecter": "Azyndan 8 simwoldan ybarat bolmaly",


  ////////////////////////////pages/Room/feedback
  "Feedback type": "Arzyň görnüşi",
  "complain": "Şikaýat",
  "recommend": "Teklip",
  "Your text": "Siziň ýazgyňyz",

  ////////////////////////////pages/Room/feedback
  "Select department": "Bölümi saýlaň",
  "Meeting purpose": "Duşuşygyň temasy",
  "Select your free date": "Banka gelip biljek senäniňizi saýlaň",
  "Meet time": "Bellenen sene",
  /////////////////////////////////////////
  "New request": "Täze ýüzlenme",
  "Profile Edit": "Üýtgetmek",
  "MiddleName": "Atasyn Ady",
  "ChooseRegion": "Bank şahamçasyny saýlaň",
  "Edit Profile": "Profili Üýtgetmek",

  "Add MoreInfo": "Goşmaça maglumat goşuň",
  "Save MoreInfo": "Has giňişleýin maglumat tapyň",
  "Save Changes": "Üýtgetmeleri ýatda sakla",
  "Message from organization": "Guramanyň ýazmaça haýyşy",
  "mary": "Mary",
  "balkan": "Balkan",
  "dasoguz": "Dashoguz",
  "ashgabat": "Ashgabat",
  "lebap": "Lebap",
  "Online Payment": "Töleg maglumatlary",
  "Total Amount": "Jemi",
  "Passport": "Passport",
  "Birth date": "Doglan güni",
  "PrivateAutoFill": "Jikme-jiklikleriňiz awtomatiki usulda doldurylýar, ýöne üýtgedip bilersiňiz",
  "ImageChangeHelpText": "Bank bilen habarlaşýan hatyňyzy skanerlemek üçin '+' basyň",
  "ScanAreaMove": "Skaneri şu ýere geçiriň",
  "supportScanType": "Goldanýan format",
  "writeYourMessage": "Habaryňyzy giriziň",
  "originNumber": "Çykýan belgisi",
  "scanDate": "Select application date",
  "notEntered": "Görkezilmedi",
  "dataSuccesChanged": "Profil jikme-jiklikleri üstünlikli üýtgedildi",
  "dataSuccesSaved": "Profil maglumatlaryňyz üstünlikli ýatda saklandy",
  "Error": "Roralňyşlyk",
  "PleaseFillYourProfileData": "Profiliňiz barada goşmaça maglumatlary doldurmagyňyzy haýyş edýäris",
  "Fill": "Doldur",
  "noNotifications": "Sizde entek bildiriş ýok",
  "uploadMaxSizeError": "Roralňyşlyk. Faýlyň ölçegi 2 megabaýtdan geçdi. Agramy 2 megabaýtdan az bolan faýly saýlaň",
  "fileErrorTryAgain": "Ошибка. При попытке удалить файл",
  "attention": "Üns beriň",
  "descriptionFillProfile": "Şahsy hasabyňyza dürli amallar üçin awtomatiki usulda doldurmak üçin profil maglumatlaryňyzy doldurmagyňyzy maslahat berýäris. Bu habary äsgermezlik edip, 'Dowam et' düwmesine basyp bilersiňiz, şonda meýdanlary awtomatiki doldurmazdan el bilen dolduryp bilersiňiz.",
  "fillOutData": "Jikme-jiklikleri dolduryň",
  "continue": "Dowam et",
  "loading": "Ýüklemek",
  "faceType5": "Fiziki şahs",
  "faceType1": "Ýurdiki şahs",
  "check": "Barlaň",
  "sentAgain": "Has köp iber",
  "WeSentOnYourEmailMessage": "E-poçtaňyza iberdik",
  "MessageForEdentify": "habar, dowam etmek üçin şahsyýetiňizi tassyklaň",
  "YourEmailIsNotFerfied": "E-poçtaňyz tassyklanmady!",
  "anotherEmailMessageSented": "Gaýtadan poçta ugradyldy",
  "emailSentVerifyError":"Habar ugradylanda näsazlyk ýüze çykdy",
  "noVerifiedEmail": "E-poçta tassyklamasy talap edilýär",
  "verifiedEmail": "E-poçta tassyklandy",
  "GivenDateCantBeAFuture" : "Berlen senesi geljekde bolup bilmez",
  "Add": "Goş",
  "youEmailSuccesVerified": "E-poçtaňyz üstünlikli tassyklandy",
  "errorVerifyEmail": "E-poçta tassyklanmadyk",
  "succesVerifyEmail": "E-poçtaňyzy barlaň",
  "CreateDoc": "Arza Döret",
  "EmailVerifiedProfile": "Email confirmation",
  "ru": "Русский",
  "en": "English",
  "tm": "Turkmen",
  "step": "Ädim"
}
export default tm 