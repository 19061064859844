const en: any = {
  // loader
  'loading-main': "Please wait while the data is loading...",
  // auth Header
  "Individual": 'Individual',
  "Entity": "Entity",
  // auth footer
  "phone": "telephone",
  "Address": "Address",
  "All right reserved": "All Rights Reserved. The State Commercial Bank of Turkmenistan «Türkmenistan»",
  "slogan": "Create your future with us!",
  "address_of_the_bank": "744000, Aşgabat şäheriniň Görogly köçesi, 79",
  // Login page
  "Sign in": "Entrance",
  "Sign up": "Register",
  "Forgot password": "Forgot your password",
  "Enter": "To come in",
  "Username": "Username",
  "Password": "Password",
  "Invalid grand": "Incorrect username or password",

  // register page
  "success registered": "You have successfully registered",
  "user has already registered": "A user with the same name already exists",
  "email has already registered": "A user with this email already exists",
  "Is not same passwords": "Password mismatch",
  "Minimum 8 symbol": "Minimum 8 characters",
  "Name": "Name",
  "Surname": "Surname",
  "Phone number": "Phone number",
  "Email": "Email",
  "Input email correctly": "Enter your email correctly",
  "User data": "User data",
  "Private data": "Personal data",
  "Password confirm": "Re-enter your password",
  "Required": "This field is required",
  "Back": "Back",
  "register disabled": "LEGAL ENTITIES NEED TO CONTACT THE BANK TO CONNECT TO THIS SERVICE",
  // request status
  "Successfully sent": "successfully sent",
  "filled inputs": "Completed fields",
  // room header
  "Call-center": "Information",
  "Profile": "Profile",
  "Change password": "Change password",
  "Logout": "Go out",
  "Are you sure to exit": "Do you want to go out?",
  // room
  "Welcome": 'Welcome',
  // maintable
  "Type": 'Type',
  "Date": 'Date of creation',
  "Status": "Status",
  "Action": "Action",
  "Per page": "On every page",
  "Rejected": "Denied",
  "Done": "Done",
  "Success": "Successfully",
  "Pending": "Expectation",
  // ViewItem
  "Info": "Information",
  "Notes": "Notes",
  "type of document": "Document type",
  "type of card": "Card type",
  "type of credit": "Loan type",
  "By who": "Applicant's name",
  "Is hurry": 'Service "Urgent"',
  "With delivery": '"Delivery" service',
  "Yes": "Yes",
  "No": "No",
  "Max credit amount": "Maximum loan amount",
  "Credit amount": "Amount of credit",
  "Credit percentage": "Interest rate",
  "Credit term": "Credit term",
  "Credit repayment base": "Ensuring debt repayment",
  "credit repayment sources": "Sources of debt repayment",
  "credit repayment ways": "Debt repayment methods",
  "No note": "No notes",
  //steps
  "Select type of card": "Select card type",
  "Input private data": "Customer data",
  "Card data": "Data for receiving a card",
  "Extra data": "Additional data",
  "Select payment type": "Select payment type",
  // private date
  "Input name correctly": "Enter your name correctly",
  "Input surname correctly": "Enter your last name correctly",
  "Input thirdname correctly": "Enter your middle name correctly",
  "Input birthdate correctly": "Enter your date of birth correctly",
  //
  "Thirdname": "Surname",
  "Birthdate": "date of birth",
  "Passport serie": "Passport series",
  "Passport given date": "Passport issue date",
  "Passport given place": "Place of passport issue",
  "Gender": "Floor",
  "Male": "Male",
  "Female": "Female",
  "male": "Male",
  "female": "Female",
  "Turkmen": "Turkmen",
  "turkmen": "Turkmen",
  "Other": "Other",
  "Nationality": "Nationality",
  "0": "No",
  "1": "Yes",
  "false": "No",
  "true": "Yes",
  /////////////////////////////çard info
  "Name latin": "Name (in Latin letters)",
  "Surname latin": "Last name (in Latin letters)",
  "Input latin": "Only in Latin letters",
  "sms connection": "SMS connection",
  "Home phone": "Home phone",
  "Birth place": "Place of Birth",
  "Code word to define": "Code word for your identification when contacting the bank",
  "Secret word": "A codeword",
  "Note": "The note",
  "Select branch": "Select head bank or bank branch",
  "Services": "Services",
  "Next": "Forward",
  "Card payment": "Card payment",
  "Summary payment": "Payment amount",
  "Confirm the data": "Confirm details",
  "Branch": "Head bank or bank branch",
  "I confirm": "I confirm",
  "Correct": "To correct",
  ////////////new Credit request
  "Credit data": "Credit details",
  "Credit calculator": "Credit calculator",
  "Select type of credit": "Select loan type",
  "year-a": "of the year",
  "year": "year",
  "years": "years",
  "monthes": "months",
  "thousand": "thousand",
  "month": "month",
  "month-a": "months",
  "monthly payment": "Monthly payment",
  "Percentage payment": "Interest payment",
  "Credit payment": "Payment to repay the principal amount",
  "Need salary for credit": "Income",
  "Credit requirement": "Ensuring debt repayment",
  "credit calc notice 1": "The monthly payment amount is determined by the loan term",
  "credit calc notice 2": "The calculation was made taking into account the following: 365 days in 1 year and 30 days in 1 month on average",
  "Salary or family income": "Amount of monthly salary or family income",
  "Can input salary or family income": "The amount of monthly salary or family income can be entered",
  "Work position": "Job title",
  "Work experience": "Length of service since last place of work",
  "Work place": "Place of work",
  ///////////////request result/////////////
  'Success requested': "Your application has been successfully accepted",
  "Some error, try it again": "Error, please try again",
  "Notice you about website": 'We inform you that the list of documents is posted on the official website of the Joint Stock Commercial Bank "Rysgal". To go to the page',
  "follow the link": "follow this link",
  "Responsible staff": "Person responsible for you",
  "Necessary docs": "Required documents",
  "Bank address": "Address of the bank",
  "Room location": "Office location",
  "Floor": "Floor",
  "Room": "Cabinet",
  /////////////////new document request
  "Online service payment": "Online hyzmat",
  "Is bussinessman": "Telekeçimi",
  "Select type of document": "Select document type",
  "Document data": "Data for the document",

  //////////////////EmptyData.js
  "Yuzlenme yok": "No applications",
  ////////////////////HomePhone.js
  "Input home phone correctly": "Enter your home phone number correctly",
  ///////////////////////Nationality.js
  "Input nationality correctly": "Enter your nationality correctly",
  ///////////////////////////PassportGiýenDate.js
  "Проверьте правильность даты выдачи": "Check that the issue date is correct",
  "Дата выдачи не может быть в будущем": "Issue date cannot be in the future",
  ///////////////////////PassportGivenPlace
  "Input passport given place correctly": "Enter the correct place in your passport",
  ////////////////////////////Passport number
  "Input passport serie correctly": "Enter the passport series correctly",
  //////////////////////////////EmailÝerify.js
  "Açary çalyşmak üçin ssylka email-e ugradyldy": "A link has been sent to your email to change your password.",
  "Email-i dogry giriziň": "Enter your email address correctly",
  "Açary dikeltmek": "Password reset",
  "Email dogry girizin": "Enter your email address correctly",
  "Ýapmak": "Close",
  "Ugratmak": "Send",
  ///////////////////////Foooter.js
  "Geljegiňizi biz bilen dörediň!": "Create the future with us!",
  ///////////////////CardInfoCheck.js
  "Informations": "Information",
  "Töleg maglumatlary": "Payment Information",
  "Agree to payment policy": "I agree with the payment policy",
  ///////////////////////CardSelect.js
  "Hasaplaşyk walýutasy": "Rasset currency",
  ////////////////CreditCalc.js
  "Karzyň möhletine görä her aýdaky tölegiň möçberi kesgitlenýär": "The monthly payment amount is determined by the loan term",
  "1 ýylda 365 gün we ortaça 1 aýda 30 gün hasaby bilen": "Calculated taking into account: 365 days a year and 30 days a month",
  /////////////////////CreditInfoCheck.js
  "Karz maglumatlary": "Loan information",
  "Agree to private policy": "I agree with the privacy policy",
  //////////////////////CreditInfoSection.js
  "Not enough salary or family income": "Lack of salary or family income",
  "Not enough experience": "Not enough experience",
  ///////////////////////////////////Header
  "Home page": "Home",

  //////////////////////////////Sidebar
  "Card section": "Card department",
  "Document section": "Reference",
  "Feedback section": "Complaints and suggestions",
  "online reception": "Online reception",
  "profile": "Profile",
  "Credit section": "Credit department",
  /////////////////////////Restore.jsx
  "Açar üstünlikli dikeldildi": "The key was successfully restored",
  "Näsazlyk, dikeltme wagty tamamlandy": "Problem, recovery time has expired",
  "Email-i dogry girizin": "Enter your email address correctly",
  "Näsazlyk, täzeden barlap görüň": "Error, please try again",
  "Gizlin açar söz gaýtalap": "Reenter password",
  "Açarlar deň gelenok": "Keys don't match",
  "Dikeltmek": "Restore",
  ///////////////////////////////////pages/Room/Card
  "Kart almak üçin onlaýn ýüzlenme": "Online application for a card",
  "Ýüzlenme ugratmak": "Send a request",
  ////////////////////////pages/Room/Credit
  "Karz almak üçin onlaýn ýüzlenme": "Online application for a loan",
  //////////////////////////////////////////pages/Room/Document.jsx
  "Güwanama üçin onlaýn ýüzlenme": "Online application for a document",
  /////////////////////////////pages/Room/Profile/Offers
  "Offered cards": "Card applications",
  "Offered credits": "Loan applications",
  "Offered documents": "Applications for a document",

  //////////////////////////////pages/Room/passwordChange
  "old password": "Old Password",
  "new password": "New Password",
  "new password confirm": "New password again",
  "Change": "Change",

  "easy code": "Easy password",
  "middle code": "Average password",
  "hard code": "Complex password",
  "input data is incorrect": "Incorrect data entered",
  "password successfully changed": "Password changed successfully",
  "some advices for password": "Some tips",
  "at least one uppercase": "Password must contain at least 1 uppercase letter (A-Z)",
  "at least one number": "Password must contain at least 1 digit (0-9)",
  "at least eight charecter": "Password must contain at least 8 characters",

  ////////////////////////////pages/Room/feedback
  "Feedback type": "Select application type",
  "complain": "Complaint",
  "recommend": "Offer",
  "Your text": "Your text",
  ////////////////////////////pages/Room/feedback
  "Select department": "Select department",
  "Meeting purpose": "Topic of the meeting",
  "Select your free date": "Select a date to visit the bank",
  "Meet time": "Selected date",
  /////////////////////////////////////////
  "New request": "New application",
  "Profile Edit": "Edit",
  "MiddleName": "Middle name",
  "ChooseRegion": "Select your bank branch",
  "Edit Profile": "Edit profile",
  
  "Add MoreInfo": "Add additional information",
  "Save MoreInfo": "Save additional information",
  "Save Changes": "Save changes",
  "Message from organization": "Written request from the organization",
  "mary": "Mary",
  "balkan": "Balkan",
  "dasoguz": "Dashoguz",
  "ashgabat": "Ashgabat",
  "lebap": "Lebap",
  "Online Payment": "Online Payment",
  "Total Amount": "Total Amount",
  "Passport": "Passport",
  "Birth date": "Birthday",
  "PrivateAutoFill": "Your details are filled in automatically but you can change them",
  "ImageChangeHelpText": "Click on '+' to attach a scan of your letter contacting the bank",
  "ScanAreaMove": "Move the scan here",
  "supportScanType": "Supported Format",
  "writeYourMessage": "Enter your message",
  "originNumber": "Outgoing number",
  "scanDate": "Select application date",
  "errorScanDate": "Проверьте правильность даты выдачи",
  "helperScanText": "Прошу заполните ваши данные",
  "notEntered": "Not Entered",
  "dataSuccesChanged": "Data changed successfully",
  "dataSuccesSaved": "Your profile data was successfully saved",
  "Error": "Error",
  "PleaseFillYourProfileData": "Please fill in additional information about your profile",
  "Fill": "Fill",
  "noNotifications": "You don't have any notifications yet",
  "uploadMaxSizeError": "Ошибка. Размер файла привысил лимит в 2 мегабайт. Пожалуйста выберите файл который весит меньше 2 мегабайт",
  "fileErrorTryAgain": "Error. When you try to delete a file",
  "attention": "Attention",
  "descriptionFillProfile": "We strongly recommend that you fill in your profile data for automatic completion for various actions to your personal account. You can ignore this message and click 'Continue' then you can manually fill in the data yourself without auto-filling the fields.",
  "fillOutData": "Fill in details",
  "continue": "Continue",
  "loading": "Loading",
  "faceType5": "Entity face",
  "faceType1": "Individual face",
  "check": "Check",
  "sentAgain": "Send more",
  "WeSentOnYourEmailMessage": "We sent to your email",
  "MessageForEdentify": "message, confirm your identity to continue",
  "YourEmailIsNotFerfied": "Your email has not been confirmed!",
  "anotherEmailMessageSented": "Repeated email sent",
  "emailSentVerifyError":"An error occurred while sending a repeat message",
  "noVerifiedEmail": "Email confirmation required",
  "verifiedEmail": "Email confirmed",
  "GivenDateCantBeAFuture" : "Issue date cannot be in the future",
  "Add": "Add",
  "youEmailSuccesVerified": "Your email has been successfully confirmed",
  "errorVerifyEmail": "Email confirmation error. Please check your email",
  "succesVerifyEmail": "Congratulations, you have successfully verified your email.",
  "CreateDoc": "Create a request",
  "EmailVerifiedProfile": "E-poçta tassyklamasy",
  "ru": "Русский",
  "en": "English",
  "tm": "Turkmen",
  "step": "Step"
}
export default en